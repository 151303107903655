<template>
  <span v-if="!isTransMode">
    <span class="hide">
      <slot />
    </span>
    {{ $t(stringKey) }}
  </span>
  <span v-else @click.prevent="openTransModal($event)" class="cursor-pointer" v-b-tooltip.hover :title="$t('문자열 리소스를 변경하려면 클릭하세요.')">
    <span class="hide">
      <slot />
    </span>

    {{ $t(stringKey) }}
    <i v-if="stringKey" class="fa fa-language"></i>
    <b-modal ref="transModal" :hide-footer="true" :title="$t('문자열 리소스')">
      <trans-modal :string-key="stringKey" @save-done="onTransSaveDone" />
    </b-modal>
  </span>
</template>

<script>
import TransModal from "./TransModal.vue";

export default {
  components: {
    TransModal,
  },
  props: ["label"],
  data() {
    return {};
  },
  computed: {
    isTransMode() {
      return this.$store.getters.isTransMode;
    },
    stringKey() {
      if (this.$slots.default && this.$slots.default.length > 0 && this.$slots.default[0].text) {
        return this.$slots.default[0].text.trim();
      }

      return `${this.label}`.trim();
    },
  },
  mounted() {
    this.initSetting();
  },
  methods: {
    initSetting() {},
    openTransModal(evt) {
      evt.stopPropagation();
      evt.preventDefault();

      this.$refs.transModal.show();
    },
    onTransSaveDone() {
      this.$refs.transModal.hide();
    },
  },
};
</script>
