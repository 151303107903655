<template>
	<div>
		<div class="row form-group hide">
			<div class="col-md-12">
				<label> {{ $t("문자열키") }} </label>
				<vvp rules="required|max:32" v-slot="{ errors, classes }" name="String Key">
					<input type="text" class="form-control" :class="classes" :placeholder="$t('문자열 키를 입력하세요.')" v-model="newKey" />
					<span class="invalid-feedback">{{ errors[0] }}</span>
				</vvp>
			</div>
		</div>
		<template v-for="nation in nations">
			<div class="row form-group" :key="nation.locale">
				<div class="col-md-12">
					<label> {{ nation.lang }} - {{ nation.code }} </label>
					<vvp rules="max:5000" v-slot="{ errors, classes }" :name="`Multi String ${nation.locale}`">
						<textarea rows="3" class="form-control" :class="classes" :placeholder="$t('문자열을 입력하세요.')" v-model="stringSets[nation.locale]"></textarea>
						<span class="invalid-feedback">{{ errors[0] }}</span>
					</vvp>
				</div>
			</div>
		</template>
		<div class="row form-group">
			<div class="col-md-12">
				<label> {{ $t("설명") }} </label>
				<vvp rules="max:5000" v-slot="{ errors, classes }" name="Multi String Remark">
					<input type="text" class="form-control" :class="classes" :placeholder="$t('설명을 입력하세요.')" v-model="stringSets['remark']" />
					<span class="invalid-feedback">{{ errors[0] }}</span>
				</vvp>
			</div>
		</div>
		<div slot="modal-footer" class="w-100">
			<button class="btn btn-sm btn-danger" @click="onDelete()"><i class="fa fa-times"></i> {{ $t("삭제") }}</button>
			<button class="btn btn-sm btn-primary pull-right" @click="passes(onSave)"><i class="fa fa-save"></i> {{ $t("저장") }}</button>
		</div>
	</div>
</template>

<script>
	import backEndApi from "@api/backEndApi.js";

	export default {
		components: {},
		props: ["stringKey", "save-done"],
		data() {
			return {
				newKey: "",

				stringSets: {
					en: "",
					kr: "",
					remark: "",
				},
			};
		},
		computed: {
			nations() {
				return this.$store.getters.master ? this.$store.getters.master.countryCodes : [];
			},
		},
		watch: {
			stringKey() {
				this.initSetting();
			},
		},
		mounted() {
			this.initSetting();
		},
		methods: {
			initSetting() {
				this.newKey = this.stringKey.trim();

				backEndApi.engineering.getStringSet(this.newKey).then(({ data }) => {
					if (this.$err(data)) return;

					if (!this.isEmpty(data)) {
						this.stringSets = data;
					} else {
						this.stringSets = {};

						for (let nation of this.nations) {
							this.stringSets[nation.locale] = "";
						}
					}
				});
			},
			onDelete() {},
			onSave() {
				this.alertQuestion("저장하시겠습니까?", "다국어", `${this.curGoalType} - ${this.curYear}`).then((result) => {
					if (!result.value) return;

					let data = {
						oldKey: this.stringKey,
						newKey: this.newKey,
						stringSets: this.stringSets,
					};

					backEndApi.engineering.updateStrings(data).then(({ data }) => {
						if (this.$err(data)) return;

						this.$store.dispatch("UPDATE_STRINGS"); // 리소스 문자열
						this.$emit("save-done");
					});
				});
			},
		},
	};
</script>
